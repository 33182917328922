import React, {useState, useEffect, useCallback, useRef, memo} from 'react';
import {useNavigate, useParams} from 'react-router-dom';
import axios from 'axios';
import {
    CircularProgress,
    Typography,
    Box,
    ListItem,
    ListItemText,
    Chip,
    FormControl,
    InputLabel,
    Divider,
    Select,
    MenuItem,
} from '@mui/material';
import IframeComm from 'react-iframe-comm';
import moment from 'moment';
import {debounce} from "lodash";
import {AvatarStack, Breadcrumbs, Section} from "@telegram-apps/telegram-ui";
import {translate} from "./translations";
import {useSelector, useDispatch} from "react-redux";
import useWebApp from "./twa/useWebApp";
import {Avatar as TAvatar} from "@telegram-apps/telegram-ui/dist/components/Blocks/Avatar/Avatar";
import {AssignmentPluginStyle} from "./AssignmentPluginStyle";
import CustomTextarea from "./course/components/CustomTextarea";
import {setShowNavigation} from "./store";

const AssignmentDetails = ({themeParams, language, mode = 'light'}) => {
    const {id} = useParams();
    const navigate = useNavigate();
    const [assignment, setAssignment] = useState(null);
    const [loading, setLoading] = useState(true);
    const [iframeHeight, setIframeHeight] = useState(500);
    const [iframeLoaded, setIframeLoaded] = useState(false);
    const [selectedComponent, setSelectedComponent] = useState(null);
    const token = useSelector(state => state.accessToken);
    const descriptionRef = useRef(null);
    const [drawerHeight, setDrawerHeight] = useState(1); // Состояние для управления высотой Drawer
    const [customHeight, setCustomHeight] = useState(0);
    const dispatch = useDispatch();


    let WebApp = useWebApp();

    useEffect(() => {
        if (WebApp) {
            WebApp.BackButton.show();
            WebApp.BackButton.onClick(function () {
                onClose();
                navigate(`/assignments`);
            });
            return () => {
                WebApp.BackButton.hide();
            };
        }
    }, [WebApp]);

    useEffect(() => {
        const fetchAssignment = async () => {
            if (assignment || !token) {
                setLoading(false);
                return;
            }

            try {
                const response = await axios.get(`/v1/assignments`, {
                    headers: {
                        'Authorization': `Bearer ${token}`
                    }
                });

                let assignments = response.data.data;
                let assignment = assignments.find(a => a.id === id);

                setAssignment(assignment);
                setSelectedComponent(assignment.components[0]);
                setLoading(false);
            } catch (error) {
                console.error("Ошибка при получении задания:", error);
                setLoading(false);
            }
        };

        fetchAssignment();
    }, [id, token]); // Добавлены необходимые зависимости

    useEffect(() => {
        const handleClick = (action) => {
            progressShow(action);
            handleFeedbackSubmit(action);
        };

        if (selectedComponent && WebApp) {
            if (selectedComponent?.status === "pending") {
                dispatch(setShowNavigation(false));
                buttonsShow();

                WebApp.MainButton.onClick(() => handleClick('approve'));
                WebApp.SecondaryButton.onClick(() => handleClick('reject'));

                WebApp.MainButton.setParams({
                    text: translate(language, 'approve'),
                    has_shine_effect: true,
                });

                WebApp.SecondaryButton.setParams({
                    text: translate(language, 'decline'),
                    text_color: themeParams.destructive_text_color,
                });
            }
        }
    }, [selectedComponent, WebApp]);

    const progressShow = (action) => {
        if (action === 'approve') {
            if (WebApp) WebApp.MainButton.showProgress(true);
        }
        if (action === 'reject') {
            if (WebApp) WebApp.SecondaryButton.showProgress(true);
        }
    }

    const hideProgress = (action) => {
        if (action === 'approve') {
            if (WebApp) WebApp.MainButton.hideProgress();
        }
        if (action === 'reject') {
            if (WebApp) WebApp.SecondaryButton.hideProgress();
        }
    }

    const date = (dt) => moment(dt).format('DD.MM.YY hh:mm');

    const buttonsShow = () => {
        if (WebApp) {
            WebApp.MainButton.show();
            WebApp.SecondaryButton.show();
        }
    }

    const buttonsHide = () => {
        if (WebApp) {
            WebApp.MainButton.hide();
            WebApp.SecondaryButton.hide();
        }
    }

    const setHeight = (resp) => {
        const height = JSON.parse(resp).height;
        if (height > 0) {
            setDebouncedHeight(height);
        }
    };

    const answerSubmit = (event) => {
        const r = JSON.parse(event);
    };

    const showMessageBox = (event) => {
        const r = JSON.parse(event);
        if (r.id === assignment.component_id) {
            const message = r.message;
            if (WebApp) WebApp.showAlert(message);
        }
    }

    const contentLoaded = (resp) => {
        const r = JSON.parse(resp);
        if (r.id === assignment.component_id) {
            setLoading(false);
            setIframeLoaded(true);
        }
    }

    const fixView = (event) => {
    };

    const onReceiveMessage = (event) => {
        let id = '';
        let namespace = '';
        if (event.data) {
            try {
                const data = JSON.parse(event.data);
                id = data.id;
                namespace = data.namespace;
            } catch (e) {
                return;
            }
        }

        if (namespace !== 'view') {
            return;
        }

        if (id === assignment.component_id) {
            const type = JSON.parse(event.data).type;
            switch (type) {
                case 'set_height':
                    setHeight(event.data);
                    break;
                case 'content_loaded':
                    contentLoaded(event.data);
                    break;
                case 'fix_view':
                    fixView(event.data);
                    break;
                case 'answer_submit':
                    answerSubmit(event.data);
                    break;
                case 'show_message':
                    showMessageBox(event.data);
                    break;
                case 'uploadFile':
                    uploadFile(event);
                    break;
            }
        }
    };

    const setDebouncedHeight = useCallback(debounce((height) => {
        setIframeHeight(height);
    }, 5), []);

    const uploadFile = (event) => {
    };

    const statusColors = (status) => {
        if (status === 'approved') {
            return '#A8E4A0';
        } else if (status === 'rejected') {
            return themeParams.destructive_text_color;
        } else {
            return themeParams.hint_color;
        }
    }

    const handleFeedbackSubmit = async (actionType) => {
        let feedback = descriptionRef.current.value;

        let aType = actionType;
        if (!actionType) {
            aType = 'approve';
        }

        if (feedback === "") {
            if (aType === 'approve') {
                feedback = '👍';
            } else {
                feedback = '👎';
            }
        }

        const data = {
            feedback: feedback
        };

        try {
            const response = await axios.post(`/v1/assignments/${selectedComponent.id}/${aType}`, data, {
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            });

            if (response.status === 200) {
                let statusRt = actionType === 'approve' ? 'approved' : 'rejected';
                let updateAssignment = {
                    ...assignment,
                    status: statusRt,
                    components: assignment.components.map((component) => {
                        if (component.id === selectedComponent.id) {
                            return {
                                ...component,
                                url: response.data.data,
                            };
                        }
                        return component;
                    })
                };
                setAssignment(updateAssignment);
                setSelectedComponent(updateAssignment.components.find(c => c.id === selectedComponent.id));
            }

        } catch (error) {
            console.error("Error in feedback submission:", error);
            if (WebApp) WebApp.showAlert(error.response.data.message);
        } finally {
            onClose();
            hideProgress(actionType);
        }
    };

    const handleFocus = (height, ref) => {
        setDrawerHeight(height);
        setCustomHeight(200);
    };

    const handleBlur = () => {
        setDrawerHeight(1);
        setCustomHeight(0);
    };

    const onClose = () => {
        dispatch(setShowNavigation(true));
        buttonsHide();
        handleBlur();
    }

    return (
        <Box sx={{
            backgroundColor: themeParams.background_color,
            color: themeParams.text_color,
            marginBottom: customHeight + 'px',
        }}>
            {loading && (
                <div style={{display: 'flex', justifyContent: 'center', margin: '20px 0'}}>
                    <CircularProgress/>
                </div>
            )}

            {assignment && !loading && (
                <>
                    <Box sx={{
                        padding: '20px',
                    }}>
                        <Breadcrumbs divider="chevron">
                            <Box onClick={() => {
                                onClose();
                                navigate(`/assignments`);
                            }} sx={{
                                fontSize: '0.80rem',
                                marginRight: '10px',
                                color: themeParams.section_header_text_color,
                            }}>
                                {translate(language, 'assignments')}
                            </Box>
                            <Box sx={{
                                fontSize: '0.80rem',
                                marginLeft: '10px',
                                color: themeParams.section_header_text_color,
                            }}>
                                {assignment.user.display_name}
                            </Box>
                        </Breadcrumbs>
                    </Box>

                    <Box sx={{
                        padding: '20px',
                    }}>
                        <Section header={
                            <Box sx={{
                                marginBottom: 20 + 'px',
                            }}>
                                <Chip
                                    sx={{
                                        backgroundColor: 'var(--tgui--secondary_fill)',
                                        color: statusColors(assignment.status),
                                    }}
                                    label={translate(language, assignment.status)}
                                />
                            </Box>
                        }>
                            <Box sx={{
                                padding: '20px',
                            }}>
                                <Typography variant="h5" style={{marginBottom: '20px'}}>
                                    <ListItem>
                                        <Box sx={{marginRight: '20px'}}>
                                            <AvatarStack>
                                                <TAvatar src={assignment.course?.image_url} size={48}/>
                                                <TAvatar src={assignment.user.avatar} size={48}/>
                                            </AvatarStack>
                                        </Box>
                                        <ListItemText
                                            primary={
                                                <>
                                                    <Typography variant="body1"
                                                                noWrap>{assignment.user.display_name}</Typography>
                                                </>
                                            }
                                            secondary={
                                                <>
                                                    <Typography variant="body2"
                                                                sx={{color: themeParams.subtitle_text_color}}>
                                                        {assignment.course?.title}
                                                    </Typography>
                                                </>
                                            }
                                        />
                                    </ListItem>
                                </Typography>
                                <FormControl fullWidth style={{marginBottom: '20px'}}>
                                    <InputLabel>{translate(language, 'attempts')}</InputLabel>
                                    <Select
                                        value={selectedComponent?.id}
                                        onChange={(e) => setSelectedComponent(assignment?.components.find(c => c.id === e.target.value))}
                                    >
                                        {assignment?.components.map((component, index) => (
                                            <MenuItem key={component.id} value={component.id}>
                                                {` 🗒️ №${assignment?.components.length - index} ${date(component.created_at)}`}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>

                                <Divider sx={{
                                    paddingTop: '10px',
                                    color: themeParams.section_separator_color,
                                }}/>

                                <Box sx={{
                                    padding: '20px',
                                }}>
                                    <IframeComm
                                        key={"view_frame_" + assignment.id}
                                        postMessageData={{
                                            bookletixMessage: {
                                                css: AssignmentPluginStyle(
                                                    100,
                                                    '#fff',
                                                    '#3A205B',
                                                    themeParams.text_color,
                                                    themeParams.bg_color,
                                                    themeParams.hint_color,
                                                    themeParams.link_color,
                                                    themeParams.button_color,
                                                    themeParams.button_text_color,
                                                    themeParams.secondary_bg_color,
                                                    selectedComponent?.status === "pending",
                                                ),
                                                data: JSON.stringify({
                                                    isSyllabus: true,
                                                    mode: mode,
                                                    language: language,
                                                }),
                                            }
                                        }}
                                        attributes={{
                                            src: selectedComponent?.url,
                                            frameBorder: 0,
                                            height: iframeHeight,
                                            width: '100%',
                                            allowFullScreen: true,
                                            scrolling: 'no',
                                            allow: 'camera; microphone',
                                            style: {
                                                opacity: iframeLoaded ? 1 : 0,
                                                transition: 'opacity 0.3s ease-in-out',
                                            },
                                        }}
                                        handleReceiveMessage={onReceiveMessage}
                                    />
                                </Box>
                            </Box>
                        </Section>

                        {assignment && assignment.status === "pending" && (
                        <Section header={translate(language, 'let_feedback')}>

                            <Box sx={{
                                padding: '20px',
                            }}>
                            <CustomTextarea
                                rows={drawerHeight}
                                label={translate(language, 'assignment_comment')}
                                ref={descriptionRef}
                                onFocus={() => handleFocus(5)}
                                onBlur={handleBlur}
                                themeParams={themeParams}
                                placeholder={"...."}
                            />
                            </Box>
                        </Section>
                        )}

                    </Box>

                </>
            )}
        </Box>
    );
};

export default memo(AssignmentDetails);