import React, { useState, useEffect } from 'react';
import {
    Avatar,
    Typography,
    Card,
    Box,
    LinearProgress,
    Divider,
    Skeleton,
} from '@mui/material';
import { styled } from '@mui/system';
import { useSelector } from "react-redux";
import { Breadcrumbs } from '@telegram-apps/telegram-ui';
import { translate } from './translations';

const StyledCard = styled(Card)(({ themeParams }) => ({
    backgroundColor: themeParams.section_bg_color,
    borderRadius: '20px',
    padding: '20px',
    marginBottom: '20px',
}));

const StyledTypography = styled(Typography)(({ themeParams }) => ({
    color: themeParams.text_color,
}));

const StyledLinearProgressContainer = styled(Box)({
    position: 'relative',
    width: '100%',
    margin: '10px 0',
});

const StyledLinearProgress = styled(LinearProgress)(({ themeParams }) => ({
    height: '20px',
    borderRadius: '3px',
    backgroundColor: themeParams.secondary_bg_color,
    '& .MuiLinearProgress-bar': {
        borderRadius: '6px',
        backgroundColor: themeParams.button_color,
    },
}));

const ProgressText = styled(Typography)(({ themeParams }) => ({
    position: 'absolute',
    left: '50%',
    top: '50%',
    transform: 'translate(-50%, -50%)',
    color: themeParams.button_text_color,
    fontWeight: 'bold',
}));

const SectionNameTypography = styled(Typography)(({ themeParams }) => ({
    color: themeParams.section_header_text_color,
    fontSize: '0.80rem',
    marginLeft: '10px',
    marginTop: '20px',
    textAlign: 'left',
    textTransform: 'uppercase',
    letterSpacing: '0.1em',
}));

const StudentDetailSkeleton = ({ themeParams, language }) => {
    return (
        <Box sx={{ padding: 2 }}>
            <Box sx={{ padding: '20px', backgroundColor: themeParams.background_color }}>
                <Breadcrumbs divider="chevron">
                    <Skeleton variant="text" width={100} height={20} />
                    <Skeleton variant="text" width={150} height={20} />
                </Breadcrumbs>
            </Box>

            <StyledCard
                themeParams={themeParams}
                sx={{
                    textAlign: 'center',
                    padding: 4,
                    borderRadius: '20px',
                    boxShadow: '0 4px 12px rgba(0, 0, 0, 0.1)',
                }}
            >
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        gap: 2,
                    }}
                >
                    <Skeleton variant="circular" width={120} height={120} />
                    <Skeleton variant="text" width="60%" height={30} />
                    <Skeleton variant="text" width="40%" height={20} />
                    <Skeleton variant="text" width="40%" height={20} />
                </Box>
            </StyledCard>

            <SectionNameTypography themeParams={themeParams}>
                {translate(language, 'enrolled_courses')}
            </SectionNameTypography>

            <Box
                sx={{
                    mt: 2,
                    backgroundColor: themeParams.section_bg_color,
                    borderRadius: '20px',
                    padding: 3,
                }}
            >
                {Array.from({ length: 3 }).map((_, index) => (
                    <React.Fragment key={index}>
                        <Box
                            display="flex"
                            gap={2}
                            sx={{
                                paddingY: 2,
                                borderBottom: `1px solid ${themeParams.accent_color}`,
                            }}
                        >
                            <Skeleton variant="rectangular" width={50} height={50} sx={{ borderRadius: '12px' }} />
                            <Box flexGrow={1}>
                                <Skeleton variant="text" width="70%" height={20} />
                                <Skeleton variant="text" width="90%" height={15} sx={{ mt: 1 }} />
                                <StyledLinearProgressContainer sx={{ mt: 1 }}>
                                    <StyledLinearProgress
                                        themeParams={themeParams}
                                        variant="determinate"
                                        value={50}
                                        sx={{
                                            height: 8,
                                            borderRadius: '6px',
                                        }}
                                    />
                                    <ProgressText themeParams={themeParams}>
                                        <Skeleton variant="text" width="20%" height={20} />
                                    </ProgressText>
                                </StyledLinearProgressContainer>
                            </Box>
                        </Box>
                        {index !== 2 && <Divider />}
                    </React.Fragment>
                ))}
            </Box>
        </Box>
    );
};

export default StudentDetailSkeleton;