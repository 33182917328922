import React, { useState, useEffect, memo } from 'react';
import axios from 'axios';
import {
    Avatar,
    Typography,
    Pagination,
    Box,
    Divider,
    MenuItem,
    Skeleton,
    Button,
    Select,
} from '@mui/material';
import { styled } from '@mui/system';
import { translate } from './translations';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

const StyledSelectContainer = styled(Box)(({ themeParams }) => ({
    marginBottom: '16px',
    padding: '16px',
    backgroundColor: themeParams.section_bg_color,
    borderRadius: '10px',
}));

const StyledLabel = styled(Typography)(({ themeParams }) => ({
    color: themeParams.section_header_text_color,
    fontSize: '0.80rem',
    textTransform: 'uppercase',
    letterSpacing: '0.1em',
    marginBottom: '8px',
}));

const StyledSelect = styled(Select)(({ themeParams }) => ({
    width: '100%',
    fontSize: '16px',
    color: themeParams.text_color,
    borderRadius: '12px',
    marginBottom: '10px',
    border: `1px solid ${themeParams.section_header_text_color}`,
    backgroundColor: themeParams.secondary_bg_color,
    outline: 'none',
    '& .MuiSelect-icon': {
        color: themeParams.text_color,
    },
    '&:focus': {
        border: `2px solid ${themeParams.button_color}`,
    },
}));

const Students = ({ themeParams, language }) => {
    const [students, setStudents] = useState([]);
    const [loading, setLoading] = useState(false);
    const [page, setPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const [courses, setCourses] = useState([]);
    const [selectedCourse, setSelectedCourse] = useState('');
    const token = useSelector((state) => state.accessToken);
    const pageSize = 10;
    const navigate = useNavigate();

    const fetchCourses = async () => {
        try {
            const response = await axios.get('/v1/courses?page=1&size=100', {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });
            setCourses(response.data.data || []);
        } catch (error) {
            console.error("Ошибка при загрузке курсов:", error);
        }
    };

    const fetchStudents = async (currentPage, courseId = '') => {
        setLoading(true);
        try {
            const response = await axios.get(`/v1/students`, {
                params: { size: pageSize, page: currentPage, course_id: courseId },
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });

            setStudents(response.data.data || []);
            setTotalPages(response.data.pagination.total_pages || 1);
        } catch (error) {
            console.error("Ошибка при загрузке студентов:", error);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        if (token != null) {
            fetchCourses();
        }
    }, [token]);

    useEffect(() => {
        if (token != null) {
            fetchStudents(page, selectedCourse);
        }
    }, [page, selectedCourse, token]);

    const handleCourseChange = (event) => {
        setSelectedCourse(event.target.value);
        setPage(1);
    };

    const resetFilter = () => {
        setSelectedCourse('');
        setPage(1);
    };

    const renderSkeleton = () => (
        <>
            {Array.from({ length: pageSize }).map((_, index) => (
                <>
                    <Box
                        key={index}
                        sx={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            borderRadius: '10px',
                            padding: 1,
                            mb: 1,
                            backgroundColor: themeParams.section_bg_color,
                        }}
                    >
                        <Box sx={{ flexGrow: 1 }}>
                            <Skeleton variant="text" width="60%" height={20} />
                            <Skeleton variant="text" width="40%" height={15} sx={{ mt: 1 }} />
                        </Box>
                        <Skeleton variant="circular" width={50} height={50} />
                    </Box>
                    {index !== pageSize - 1 && <Divider />}
                </>
            ))}
        </>
    );

    return (
        <Box sx={{ padding: 2, minHeight: '100vh' }}>
            <StyledSelectContainer themeParams={themeParams}>
                <StyledLabel themeParams={themeParams}>{translate(language, 'filter_by_course')}</StyledLabel>
                <StyledSelect
                    value={selectedCourse}
                    onChange={handleCourseChange}
                    themeParams={themeParams}
                    displayEmpty
                >
                    <MenuItem value="">{translate(language, 'all_courses')}</MenuItem>
                    {courses.map((course) => (
                        <MenuItem key={course.id} value={course.id}>
                            {course.title}
                        </MenuItem>
                    ))}
                </StyledSelect>
                {selectedCourse && (
                    <Button
                        onClick={resetFilter}
                        color="primary">
                        {translate(language, 'clear_filter')}
                    </Button>
                )}
            </StyledSelectContainer>

            <Typography
                variant="body2"
                sx={{
                    ml: 1,
                    color: themeParams.section_header_text_color,
                    textTransform: 'uppercase',
                    mb: 2,
                }}
            >
                {translate(language, 'students')}
            </Typography>

            <Box
                sx={{
                    mt: 2,
                    backgroundColor: themeParams.section_bg_color,
                    borderRadius: '20px',
                    padding: 2,
                }}
            >
                {loading ? (
                    renderSkeleton()
                ) : (
                    students.length > 0 ? (
                        students.map((student, index) => (
                            <>
                                <Box
                                    key={student.id}
                                    sx={{
                                        display: 'flex',
                                        justifyContent: 'space-between',
                                        borderRadius: '10px',
                                        padding: 1,
                                        mb: 1,
                                    }}
                                    onClick={() => navigate(`/students/${student.id}`)}
                                >
                                    <Box>
                                        <Typography variant="body1">
                                            {`${student.first_name || ''} ${student.last_name || ''}`.trim() ||
                                                student.username ||
                                                translate(language, 'unknown_user')}
                                        </Typography>
                                        <Typography variant="body2" sx={{ color: '#888' }}>
                                            {student.username || student.ref_id}
                                        </Typography>
                                    </Box>
                                    <Avatar
                                        src={student.avatar}
                                        alt={student.first_name || student.username || 'User'}
                                        sx={{ width: 50, height: 50 }}
                                    />
                                </Box>
                                {index !== students.length - 1 && <Divider />}
                            </>
                        ))
                    ) : (
                        <Typography variant="body2" color="textSecondary" align="center" marginY={2}>
                            {translate(language, 'no_students')}
                        </Typography>
                    )
                )}
            </Box>

            {totalPages > 1 && (
                <Pagination
                    count={totalPages}
                    page={page}
                    onChange={(event, value) => setPage(value)}
                    shape="rounded"
                    sx={{
                        marginTop: 4,
                        display: 'flex',
                        justifyContent: 'center',
                        '& .MuiPaginationItem-root': {
                            color: `${themeParams.button_text_color}`,
                            fontSize: '18px',
                        },
                        '& .Mui-selected': {
                            backgroundColor: `${themeParams.accent_text_color}`,
                            color: '#fff',
                        },
                    }}
                />
            )}
        </Box>
    );
};

export default memo(Students);