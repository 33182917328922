import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import store from './store';
import { AppRoot } from '@telegram-apps/telegram-ui';

const root = ReactDOM.createRoot(document.getElementById('root'));

// Определяем, использовать ли StrictMode
const AppWrapper = process.env.NODE_ENV !== 'production' ? (
    <React.StrictMode>
        <BrowserRouter>
            <AppRoot>
                <App />
            </AppRoot>
        </BrowserRouter>
    </React.StrictMode>
) : (
    <BrowserRouter>
        <AppRoot>
            <App />
        </AppRoot>
    </BrowserRouter>
);

root.render(
    <Provider store={store}>
        {AppWrapper}
    </Provider>
);
