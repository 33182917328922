import React, { useState, useEffect, useRef } from 'react';
import { Button, Drawer, Slide, Typography, IconButton, Container, Box, Grid, CircularProgress, FormControlLabel, Checkbox } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import axios from 'axios';
import { useDispatch, useSelector } from 'react-redux';
import './Courses.css';
import CustomInput from "./components/CustomInput";
import CustomTextarea from "./components/CustomTextarea";
import CustomSelect from "./components/CustomSelect";
import CourseCoverSelector from './components/CourseCoverSelector';
import {translate} from "../translations";
import {setSelectedCourse} from "../store"; // Убедитесь, что путь правильный
import { styled } from '@mui/system';
import { processImageWithExif } from './exifHandler';
import SubscriptionDialog from "../SubscriptionDialog";

// Стилизованный лейбл
const StyledLabel = styled('label')(({ themeParams }) => ({
    fontSize: '12px',
    color: themeParams.section_header_text_color,
    marginBottom: '5px',
    textTransform: 'uppercase',
}));

// Стилизованный чекбокс
const StyledCheckbox = styled(Checkbox)(({ themeParams }) => ({
    color: `${themeParams.section_header_text_color} !important`,
    '&.Mui-checked': {
        color: `${themeParams.button_color} !important`,
    },
}));

const CourseEditModal = ({ themeParams, open, onClose, language }) => {
    const course = useSelector(state => state.selectedCourse);
    const [title, setTitle] = useState(course.title || '');
    const [description, setDescription] = useState(course.description || '');
    const [customCoverImage, setCustomCoverImage] = useState(course.settings?.custom_cover_image || false);
    const [preset, setPreset] = useState(course.settings?.course_cover_preset || 'bold');
    const [isPublish, setIsPublish] = useState(course.is_publish || false);
    const [bots, setBots] = useState([]);
    const [selectedBot, setSelectedBot] = useState(course.bot_id || null);
    const [availableBotsLoader, setAvailableBotsLoader] = useState(true);
    const [loading, setLoading] = useState(false);
    const [drawerHeight, setDrawerHeight] = useState('90vh'); // Состояние для управления высотой Drawer
    const dispatch = useDispatch();
    const accessToken = useSelector(state => state.accessToken);
    const [file, setFile] = useState(null);
    const [isSubscriptionDialogOpen, setSubscriptionDialogOpen] = useState(false);

    const handleFileChange = (file) => {
        setFile(file);
    };

    const scrollContainerRef = useRef(null);

    useEffect(() => {
        const fetchAvailableBots = async () => {
            try {
                const response = await axios.get(`/v1/bots/available?limit=100${ course.bot_id ? `&active_bot_id=${course.bot_id}` : `` }`, {
                    headers: {
                        'Authorization': `Bearer ${accessToken}`,
                        'Content-Type': 'application/json',
                    },
                });
                if (response.status === 200) {
                    setBots([{ id: '00000000-0000-0000-0000-000000000000', name: translate(language, 'empty') }, ...response.data.data]);
                }
            } catch (error) {
                if (error.response?.data?.type === 'plan_limit_exceeded') {
                    setSubscriptionDialogOpen(true);
                }

                console.error("Failed to fetch available bots:", error);
            } finally {
                setAvailableBotsLoader(false);
            }
        };
        fetchAvailableBots();
    }, [course.bot_id, accessToken]);

    // Функция для управления высотой Drawer и прокрутки к активному полю
    const handleFocus = (height, ref) => {
        setDrawerHeight(height); // Устанавливаем нужную высоту Drawer
        if (ref && ref.current) {
            ref.current.scrollIntoView({ behavior: 'smooth', block: 'center' }); // Прокручиваем к активному полю
        }
    };

    const handleBlur = () => {
        setDrawerHeight('90vh'); // Возвращаем высоту Drawer к исходному значению при расфокусе
    };

    const handleSelectChange = (event) => {
        setSelectedBot(event.target.value);
    };

    const uploadFile = async () => {
        // Обрабатываем изображение и учитываем EXIF-данные
        const processedFile = await processImageWithExif(file);

        const formData = new FormData();
        formData.append('file', processedFile, file.name); // Используем исправленный файл

        const response = await axios.post('/v1/storage/upload', formData, {
            headers: {
                'Content-Type': 'multipart/form-data',
                'Authorization': `Bearer ${accessToken}`
            }
        });

        if (response.status === 200) {
            console.log("Файл успешно загружен:", response.data.data);
            return response.data.data;
        } else {
            console.error("Ошибка при загрузке файла:", response);
            return null;
        }
    };

    const handleSave = async () => {
        setLoading(true);

        const data = {};
        if (title) data.title = title;
        if (description) data.description = description;
        if (customCoverImage !== undefined) data.custom_cover_image = customCoverImage;
        if (preset) data.course_cover_preset = preset;
        if (selectedBot) data.bot_id = selectedBot;
        if (isPublish !== undefined) data.is_publish = isPublish;

        if (file) {
            const newImageId = await uploadFile();
            if (newImageId) {
                data.image_id = newImageId;
            } else {
                setLoading(false);
                return;
            }
        } else if (course.image_url && !customCoverImage) {
            data.image_id = course.image_id;
        }

        try {
            const response = await axios.put(`/v1/courses/${course.id}`, data, {
                headers: {
                    'Authorization': `Bearer ${accessToken}`,
                    'Content-Type': 'application/json',
                },
            });
            dispatch(setSelectedCourse(response.data.data));
            if (response.status === 200) {
                onClose();
            }
        } catch (error) {
            if (error.response?.data?.type === 'plan_limit_exceeded') {
                setSubscriptionDialogOpen(true);
            }

            console.error('Error saving course:', error);
        } finally {
            setLoading(false);
        }
    };

    // Референсы для полей ввода
    const titleRef = useRef(null);
    const descriptionRef = useRef(null);
    const selectRef = useRef(null);

    return (
        <Drawer
            anchor="bottom"
            open={open}
            onClose={onClose}
            PaperProps={{
                style: {
                    borderTopLeftRadius: '16px',
                    borderTopRightRadius: '16px',
                    padding: '16px',
                    backgroundColor: themeParams.section_bg_color,
                    filter: 'none',
                    mixBlendMode: 'normal',
                    backgroundImage: 'none',
                    height: drawerHeight,
                    overflowY: 'auto',
                    transition: 'height 0.3s ease',
                },
            }}
        >
            <Slide direction="up" in={open} mountOnEnter unmountOnExit>
                <div ref={scrollContainerRef} style={{ overflowY: 'auto' }}>
                    <div style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                        borderBottom: `1px solid ${themeParams.section_separator_color}`,
                        paddingBottom: '8px'
                    }}>
                        <Typography sx={{
                            fontSize: '14px',
                            fontWeight: 'bold',
                            textTransform: 'uppercase',
                        }}>{translate(language, 'edit')}</Typography>
                        <IconButton onClick={onClose}>
                            <CloseIcon />
                        </IconButton>
                    </div>
                    <Container maxWidth="sm" style={{ padding: 10 }}>
                        <form onSubmit={(e) => {
                            e.preventDefault();
                            handleSave();
                        }}>
                            <Box mb={2}>
                                <CustomInput
                                    ref={titleRef}
                                    value={title}
                                    label={translate(language, 'title')}
                                    onChange={(e) => setTitle(e.target.value)}
                                    themeParams={themeParams}
                                />
                                <CustomTextarea
                                    ref={descriptionRef}
                                    label={translate(language, 'description')}
                                    value={description}
                                    onChange={(e) => setDescription(e.target.value)}
                                    onFocus={() => handleFocus('110vh', descriptionRef)} // Устанавливаем высоту и прокручиваем к полю
                                    onBlur={handleBlur}
                                    themeParams={themeParams}
                                />
                            </Box>

                            <Box mb={2}>
                                <CourseCoverSelector
                                    customCoverImage={customCoverImage}
                                    setCustomCoverImage={setCustomCoverImage}
                                    preset={preset}
                                    setPreset={setPreset}
                                    file={file}
                                    handleFileChange={handleFileChange}
                                    themeParams={themeParams}
                                    language={language}
                                />
                            </Box>

                            {availableBotsLoader ? (
                                <p>Loading bots...</p>
                            ) : (
                                <CustomSelect
                                    ref={selectRef}
                                    label={translate(language, 'available_bots')}
                                    value={selectedBot}
                                    onChange={handleSelectChange}
                                    options={bots}
                                    themeParams={themeParams}
                                />
                            )}

                            <FormControlLabel
                                control={
                                    <StyledCheckbox
                                        checked={isPublish}
                                        onChange={e => setIsPublish(e.target.checked)}
                                        themeParams={themeParams} // Передаем themeParams для стилизации чекбокса
                                    />
                                }
                                label={<StyledLabel themeParams={themeParams} onClick={() => setIsPublish(!isPublish)}>{translate(language, 'publish_course')}</StyledLabel>}
                            />

                            <div style={{
                                position: 'sticky',
                                bottom: 0,
                                backgroundColor: themeParams.section_bg_color,
                                padding: '10px 0'
                            }}>
                                <Grid container justifyContent="flex-end">
                                    <Button
                                        sx={{
                                            width: '100%',
                                            backgroundColor: themeParams.button_color,
                                            color: themeParams.button_text_color,
                                            padding: '10px',
                                            borderRadius: '12px',
                                            '&:hover': {
                                                backgroundColor: themeParams.button_color // Цвет кнопки при наведении
                                            },
                                            '&:active': {
                                                backgroundColor: themeParams.button_color // Цвет кнопки при нажатии
                                            }
                                        }}
                                        onClick={handleSave}
                                    >
                                        {loading ? (
                                            <CircularProgress size={24} sx={{ color: themeParams.button_text_color }} />
                                        ) : (
                                            translate(language, 'save')
                                        )}
                                    </Button>
                                </Grid>
                            </div>
                        </form>
                    </Container>
                </div>
            </Slide>
            <SubscriptionDialog
                themeParams={themeParams}
                open={isSubscriptionDialogOpen}
                onClose={() => setSubscriptionDialogOpen(false)}
                language={language}
            />
        </Drawer>
    );
};

export default CourseEditModal;