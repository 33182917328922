import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import {Card, Typography, List, ListItem, ListItemText, CircularProgress} from '@mui/material';
import { styled } from '@mui/system';
import SkeletonBot from './SkeletonBot';
import { translate } from './translations';
import { useSelector } from 'react-redux';

const Bots = ({ themeParams, language }) => {
    const [bots, setBots] = useState([]);
    const [loading, setLoading] = useState(true);
    const [hasMore, setHasMore] = useState(true);
    const [isFetching, setIsFetching] = useState(false);
    const token = useSelector(state => state.accessToken);
    const hasFetched = useRef(false); // Флаг для предотвращения повторного вызова

    const StyledCard = styled(Card)({
        backgroundColor: themeParams.section_bg_color,
        borderRadius: '20px',
        margin: '20px'
    });

    const SectionNameTypography = styled(Typography)({
        color: themeParams.section_header_text_color,
        fontSize: '0.80rem',
        marginLeft: '40px',
        marginTop: '20px',
        textAlign: 'left',
        textTransform: 'uppercase',
        letterSpacing: '0.1em',
    });

    useEffect(() => {
        const fetchBots = async () => {
            setIsFetching(true);
            try {
                const response = await axios.get(`/v1/bots?size=100&page=1`, {
                    headers: {
                        Authorization: `Bearer ${token}`
                    }
                });
                const newBots = response.data.data || [];
                setBots(prevBots => [...prevBots, ...newBots]);
            } catch (error) {
                console.error("Ошибка при загрузке ботов:", error);
            } finally {
                setLoading(false);
                setIsFetching(false);
            }
        };

        if (!hasFetched.current && token) {
            fetchBots();
            hasFetched.current = true; // Устанавливаем флаг после выполнения
        }
    }, [token]);

    return (
        <div>
            <SectionNameTypography>
                {translate(language, 'bots')}
            </SectionNameTypography>
            <List>
                {bots.length > 0 ? (
                    bots.map(bot => (
                        <StyledCard key={bot.id}>
                            <ListItem>
                                <ListItemText
                                    primary={
                                        bot.name ? (
                                            <span dangerouslySetInnerHTML={{ __html: bot.name.replace(/\n/g, '<br/>') }} />
                                        ) : (
                                            bot.token
                                        )
                                    }
                                    secondary={translate(language, 'date') + ": " + new Date(bot.created_at).toLocaleString()}
                                />

                            </ListItem>
                        </StyledCard>
                    ))
                ) : (
                    !loading && (
                        <Typography variant="body2" color="textSecondary" align="center" marginY={2}>
                            {translate(language, 'no_bots')}
                        </Typography>
                    )
                )}
            </List>
            {loading && (
                <>
                    <SkeletonBot themeParams={themeParams} />
                    <SkeletonBot themeParams={themeParams} />
                </>
            )}
            {isFetching && (
                <div style={{ display: 'flex', justifyContent: 'center', margin: '20px 0' }}>
                    <CircularProgress />
                </div>
            )}
            {!hasMore && !loading && bots.length > 0 && (
                <Typography variant="body2" color="textSecondary" align="center" marginY={2}>
                </Typography>
            )}
        </div>
    );
};

export default Bots;