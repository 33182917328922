import React, {useState, useEffect} from 'react';
import { styled, css } from '@mui/system';
import {AppBar, Toolbar, IconButton, Typography, Button, CircularProgress, Menu, MenuItem} from '@mui/material';
import StarIcon from '@mui/icons-material/Verified';
import {translate} from "./translations";
import useWebApp from "./twa/useWebApp"; // Import an icon for editing
import SubscriptionDialog from './SubscriptionDialog';
import Avatar from "@mui/material/Avatar";
import {useNavigate} from "react-router-dom";

const Header = ({ language, dark, themeParams, user }) => {
    const [currentUserPlan, setCurrentUserPlan] = useState(null);
    const [currentSubscriptionInfo, setCurrentSubscriptionInfo] = useState(null);
    const [isSubscriptionDialogOpen, setSubscriptionDialogOpen] = useState(false);
    const [anchorEl, setAnchorEl] = useState(null); // For controlling the context menu
    const navigate = useNavigate();

    let WebApp = useWebApp();

    useEffect(() => {
        const getUserPlan = (user) => {
            if (!user || !user.subscriptions) {
                return 'Unsubscribed';
            }

            const role = user.subscriptions;
            if (role.includes('unlimited')) {
                return 'Unlimited';
            } else if (role.includes('premium')) {
                return 'Premium';
            } else if (role.includes('standard')) {
                return 'Standard';
            }

            return 'Unsubscribed';
        };

        setCurrentSubscriptionInfo(user?.subscription_info);
        setCurrentUserPlan(getUserPlan(user));
    }, [user]);

    // Styled component for Header with static styling
    const HeaderContainer = styled(AppBar)(({ theme }) => ({
        position: 'static', // Header is positioned normally in the document flow
        backgroundColor: `${themeParams.secondary_bg_color} !important`,
        backgroundImage: 'none',
        boxShadow: 'none', // No shadow
        height: '70px', // Set a consistent height
        zIndex: 1100,
    }));

    const LogoImage = styled('img')(() => ({
        height: '30px', // Fixed height for the logo
        objectFit: 'contain',
    }));

    const StyledToolbar = styled(Toolbar)(
        css({
            display: 'flex',
            justifyContent: 'space-between', // Space between logo and tariff elements
            alignItems: 'center', // Center elements vertically
            backgroundColor: `${themeParams.secondary_bg_color} !important`,
            padding: '0 20px', // Padding around the content inside the toolbar
        })
    );

    const TariffContainer = styled('div')(
        css({
            display: 'flex',
            alignItems: 'center', // Align text and icon vertically
            gap: '3px', // Space between the tariff text and the icon
        })
    );

    const TariffText = styled(Typography)(
        css({
            fontWeight: 'bold',
            textTransform: 'uppercase', // Make tariff plan name uppercase
            fontSize: '14px', // Adjust font size as needed
            marginTop: '2px', // Adjust top margin to align with the icon
            lineHeight: 1, // Adjust line height to align with the icon
            cursor: 'pointer', // Add a pointer cursor to indicate it is clickable
        })
    );

    const handleMenuOpen = (event) => {
        navigate('/profile');
    };

    const TariffTextLeft = styled(Typography)(
        css({
            fontWeight: 'bold',
            marginLeft: '10px',
            textTransform: 'uppercase', // Make tariff plan name uppercase
            fontSize: '14px', // Adjust font size as needed
            marginTop: '2px', // Adjust top margin to align with the icon
            lineHeight: 1, // Adjust line height to align with the icon
            cursor: 'pointer', // Add a pointer cursor to indicate it is clickable
        })
    );

    // Styled Star Icon with custom color
    const CustomStarIcon = styled(StarIcon)(
        css({
            color: `${themeParams.button_color} !important`, // Set custom color
        })
    );

    const CustomStarIconGreen = styled(StarIcon)(
        css({
            color: `#00FF00 !important`, // Set custom color
        })
    );

    // Function to handle vibration on click
    const handleTariffClick = () => {
        if (navigator.vibrate) {
            navigator.vibrate(100); // Vibrate for 100 milliseconds
        }
    };

    return (
        <HeaderContainer>
            <StyledToolbar disableGutters>
                <LogoImage
                    src={
                        !dark
                            ? 'https://coob.app/assets/images/logo/logo-text.svg'
                            : 'https://coob.app/assets/images/logo/logo-text-on-dark.svg'
                    }
                    alt="Coob Logo"
                />
                <TariffContainer>
                    {!user || !currentUserPlan ? (
                        <CircularProgress size={24} color="inherit" />
                    ) : (
                        <TariffText
                            variant="body2"
                            color="textPrimary"
                            onClick={handleTariffClick}
                        >
                            {currentUserPlan === "Unsubscribed" ? (
                                <Button
                                    onClick={() => {
                                        setSubscriptionDialogOpen(true);
                                    }}
                                    sx={{
                                        width: '100%',
                                        padding: '12px',
                                        marginTop: '5px',
                                        backgroundColor: themeParams.section_bg_color, // Цвет фона кнопки
                                        color: themeParams.text_color,
                                        borderRadius: '12px',
                                        fontSize: '13px',
                                        textTransform: 'none',
                                        boxShadow: 'none',
                                        '&:hover': {
                                            backgroundColor: themeParams.section_bg_color, // Цвет фона при наведении
                                        },
                                    }}
                                >
                                    <CustomStarIcon sx={{ marginRight: '8px' }} />
                                    {translate(language, 'subscription_now')}
                                </Button>
                            ) : (
                                <CustomStarIconGreen onClick={() => {
                                    if (WebApp) {
                                        let description = translate(language, 'subscription_is_active') + '\n';
                                        if (currentSubscriptionInfo) {
                                            description += `${translate(language, currentUserPlan.toLowerCase())}\n`;

                                            currentSubscriptionInfo.end_date = new Date(currentSubscriptionInfo.end_date);
                                            let timelock  = "ru-RU";
                                            let timeOptions = { day: '2-digit', month: '2-digit', year: 'numeric' };
                                            if (language === "en") {
                                                timelock = "en-US"
                                                timeOptions = { day: '2-digit', month: '2-digit', year: 'numeric' };
                                            }
                                            description += `${translate(language, 'subscription_end_date')}: ${currentSubscriptionInfo.end_date.toLocaleDateString(timelock, timeOptions)}`;
                                        }
                                        WebApp.showAlert(description);
                                    }
                                }} sx={{ marginRight: '8px' }} />
                            )}

                        </TariffText>
                    )}
                    <TariffTextLeft>

                        {user && (
                            <>
                            <Avatar
                                onClick={handleMenuOpen}
                                alt={user.full_name.substring(0, 5)}
                                src={user.avatar}
                                sx={{ width: 28, height: 28, margin: '0 auto 0px'}}
                            />

                            </>
                        )}

                    </TariffTextLeft>
                </TariffContainer>
            </StyledToolbar>
            <SubscriptionDialog
                themeParams={themeParams}
                open={isSubscriptionDialogOpen}
                onClose={() => setSubscriptionDialogOpen(false)}
                language={language}
            />
        </HeaderContainer>
    );
};

export default Header;