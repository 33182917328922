import React from 'react';
import { Card, CardContent, ListItem, ListItemText, Skeleton, Box } from '@mui/material';
import { styled } from '@mui/system';

const SkeletonBot = ({ themeParams }) => {
    const StyledCard = styled(Card)({
        backgroundColor: themeParams.section_bg_color,
        borderRadius: '16px',
        margin: '10px 20px',
        boxShadow: '0px 2px 5px rgba(0, 0, 0, 0.1)',
    });

    return (
        <StyledCard>
            <CardContent>
                <ListItem>
                    <ListItemText
                        primary={<Skeleton variant="text" width="70%" />}
                        secondary={<Skeleton variant="text" width="50%" />}
                    />
                </ListItem>
            </CardContent>
        </StyledCard>
    );
};

export default SkeletonBot;