import React from 'react';
import { styled } from '@mui/system';
import TextField from '@mui/material/TextField';

// Создаем стилизованный контейнер для TextField
const StyledInputContainer = styled('div')({
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
});

const StyledLabel = styled('label')(({ themeParams }) => ({
    fontSize: '12px',
    color: themeParams.section_header_text_color,
    marginBottom: '5px',
    textTransform: 'uppercase',
}));

// Компонент CustomInput с использованием TextField
const CustomInput = ({
                         ref,
                         label,
                         value,
                         onChange,
                         onFocus,
                         onBlur,
                         themeParams,
                         placeholder,
                         InputProps = {}, // Прокидываем дополнительные свойства в TextField
                     }) => {
    return (
        <StyledInputContainer>
            <StyledLabel themeParams={themeParams}>{label}</StyledLabel>
            <TextField
                ref={ref}
                value={value}
                onChange={onChange}
                onFocus={onFocus}
                onBlur={onBlur}
                placeholder={placeholder}
                fullWidth
                variant="outlined"
                InputProps={{
                    style: {
                        marginBottom: '10px',
                        borderRadius: '12px',
                        color: themeParams.text_color,
                        backgroundColor: themeParams.secondary_bg_color,
                    },
                    ...InputProps, // Распространяем переданные свойства
                }}
                InputLabelProps={{
                    shrink: true,
                    style: {
                        color: themeParams.section_header_text_color,
                    },
                }}
                sx={{
                    '& .MuiOutlinedInput-root': {
                        '& fieldset': {
                            borderColor: themeParams.section_header_text_color,
                        },
                        '&:hover fieldset': {
                            borderColor: themeParams.button_color,
                        },
                        '&.Mui-focused fieldset': {
                            borderColor: themeParams.button_color,
                        },
                    },
                }}
            />
        </StyledInputContainer>
    );
};

export default CustomInput;