import React, {useState, useEffect, useCallback, memo} from 'react';
import {useNavigate, useParams} from 'react-router-dom';
import axios from 'axios';
import { Avatar, Typography, Card, Box, LinearProgress, Divider } from '@mui/material';
import { styled } from '@mui/system';
import { useSelector } from "react-redux";
import { translate } from './translations';
import {Breadcrumbs, Button, Section} from '@telegram-apps/telegram-ui';
import {truncateText} from "./helper";
import StudentDetailSkeleton from "./StudentDetailSkeleton";
import {setSelectedStep} from "./store";
import useWebApp from "./twa/useWebApp";

const StyledCard = styled(Card)(({ themeParams }) => ({
    backgroundColor: themeParams.section_bg_color,
    borderRadius: '20px',
    padding: '20px',
    marginBottom: '20px',
}));

const StyledTypography = styled(Typography)(({ themeParams }) => ({
    color: themeParams.text_color,
}));

const StyledLinearProgressContainer = styled(Box)({
    position: 'relative',
    width: '100%',
    margin: '10px 0',
});

const StyledLinearProgress = styled(LinearProgress)(({ themeParams }) => ({
    height: '20px',
    borderRadius: '3px',
    backgroundColor: themeParams.secondary_bg_color,
    '& .MuiLinearProgress-bar': {
        borderRadius: '6px',
        backgroundColor: themeParams.button_color,
    },
}));

const ProgressText = styled(Typography)(({ themeParams }) => ({
    position: 'absolute',
    left: '50%',
    top: '50%',
    transform: 'translate(-50%, -50%)',
    color: themeParams.button_text_color,
    fontWeight: 'bold',
}));

const SectionNameTypography = styled(Typography)(({ themeParams }) => ({
    color: themeParams.section_header_text_color,
    fontSize: '0.80rem',
    marginLeft: '10px',
    marginTop: '20px',
    textAlign: 'left',
    textTransform: 'uppercase',
    letterSpacing: '0.1em',
}));

const StudentDetail = ({ themeParams, language }) => {
    const { id } = useParams();
    const [student, setStudent] = useState(null);
    const [loading, setLoading] = useState(true);
    const accessToken = useSelector((state) => state.accessToken);
    const navigate = useNavigate();
    const WebApp = useWebApp();

    useEffect(() => {
        const fetchStudentDetails = async () => {
            try {
                const response = await axios.get(`/v1/students/${id}`, {
                    headers: {
                        Authorization: `Bearer ${accessToken}`,
                    },
                });
                setStudent(response.data.data);
            } catch (error) {
                console.error('Error fetching student details:', error);
            } finally {
                setLoading(false);
            }
        };

        fetchStudentDetails();
    }, [id, accessToken]);

    useEffect(() => {
        window.scrollTo(0, 0);

        if (WebApp) WebApp.BackButton.show();
        if (WebApp) {
            WebApp.BackButton.onClick(handleBackClick);
        }
        return () => {
            WebApp.BackButton.hide();
        };
    }, [WebApp]);

    // Function to convert hex to rgb
    const hexToRgb = (hex) => {
        let bigint = parseInt(hex.substring(1), 16);
        let r = (bigint >> 16) & 255;
        let g = (bigint >> 8) & 255;
        let b = bigint & 255;
        return [r, g, b];
    };

    const rgbToCss = (rgb) => `rgb(${rgb.join(', ')})`;

    const lightenColor = (rgb, amount) => {
        return rgb.map(value => Math.min(255, value + amount));
    };

    const primaryColorRgbArray = hexToRgb(themeParams.secondary_bg_color); // --tg-theme-bg-color
    const primaryColorRgb = rgbToCss(primaryColorRgbArray);

    // Apply transformation to get the secondary color
    const secondaryColorRgbArray = lightenColor(primaryColorRgbArray, 50); // Increase brightness
    const secondaryColorRgb = rgbToCss(secondaryColorRgbArray);

    const handleBackClick = useCallback(() => {
        navigate(`/students`);
    }, []);

    if (loading) return <StudentDetailSkeleton themeParams={themeParams} language={language} />;
    if (!student) return <Typography>No student found.</Typography>;

    return (
        <Box sx={{ padding: 2 }}>
            <Box sx={{
                padding: '20px',
                backgroundColor: themeParams.background_color,
                color: themeParams.text_color
            }}>
                <Breadcrumbs divider="chevron">
                    <Box onClick={handleBackClick} sx={{
                        fontSize: '0.80rem',
                        marginRight: '10px',
                        color: themeParams.section_header_text_color,
                    }}>
                        {translate(language, 'students_br')}
                    </Box>
                    <Box sx={{
                        marginLeft: '10px',
                        fontSize: '0.80rem',
                        color: themeParams.section_header_text_color,
                    }}>
                        {truncateText( student.first_name + ' ' + student.last_name || student.username || 'User'  , 15)}
                    </Box>
                </Breadcrumbs>
            </Box>

            <StyledCard
                themeParams={themeParams}
                sx={{
                    textAlign: 'center',
                    padding: 4,
                    borderRadius: '20px',
                    background: `linear-gradient(${primaryColorRgb} 10.06%, ${secondaryColorRgb} 128.06%)`,
                    boxShadow: '0 4px 12px rgba(0, 0, 0, 0.1)',
                }}
            >
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        gap: 2,
                    }}
                >
                    <Avatar
                        src={student.avatar}
                        alt={student.first_name || student.username || 'User'}
                        sx={{
                            width: 120,
                            height: 120,
                            border: `4px solid ${themeParams.accent_color}`,
                            boxShadow: '0 2px 8px rgba(0, 0, 0, 0.1)',
                        }}
                    />
                    <StyledTypography
                        themeParams={themeParams}
                        variant="h5"
                        sx={{ fontWeight: 600, marginTop: 2 }}
                    >
                        {`${student.first_name || ''} ${student.last_name || ''}`.trim()}
                    </StyledTypography>
                    {student.username && (
                        <Typography variant="body2" sx={{ fontSize: '14px', color: themeParams.text_secondary }}>
                            <strong>{translate(language, 'username_f')}:</strong> {student.username}
                        </Typography>
                    )}
                    {student.ref_id && (
                        <Typography variant="body2" sx={{ fontSize: '14px', color: themeParams.text_secondary }}>
                            <strong>{translate(language, 'id_f')}:</strong> {student.ref_id}
                        </Typography>
                    )}
                </Box>
            </StyledCard>


                <SectionNameTypography themeParams={themeParams}>
                    {translate(language, 'enrolled_courses')}
                </SectionNameTypography>

            <Box
                sx={{
                    mt: 2,
                    backgroundColor: themeParams.section_bg_color,
                    borderRadius: '20px',
                    padding: 3,
                }}
            >
                {student.courses.map((course, index) => (
                    <>
                    <Box
                        key={course.id}
                        display="flex"
                        gap={2}
                        sx={{
                            paddingY: 2,
                            borderBottom: index < student.courses.length - 1 ? `1px solid ${themeParams.accent_color}` : 'none',
                        }}
                    >
                        <Avatar
                            src={course.image}
                            alt={course.title}
                            sx={{
                                width: 50,
                                height: 50,
                                borderRadius: '12px',
                            }}
                        />
                        <Box flexGrow={1}>
                            <StyledTypography
                                themeParams={themeParams}
                                sx={{ fontWeight: 600, mb: 1 }}
                            >
                                {course.title.length > 55 ? `${course.title.substring(0, 52)}...` : course.title}
                            </StyledTypography>
                            <Typography
                                variant="body2"
                                color="textSecondary"
                                sx={{ lineHeight: 1.5, pb:1 }}
                            >
                                {course.description.substring(0, 50)}
                            </Typography>
                            {course.percent && (
                                <StyledLinearProgressContainer sx={{ mt: 1 }}>
                                    <StyledLinearProgress
                                        themeParams={themeParams}
                                        variant="determinate"
                                        value={parseInt(course.percent, 10)}
                                        sx={{
                                            color: themeParams.button_color,
                                            height: 8,
                                            p:1.3,
                                            borderRadius: '6px',
                                        }}
                                    />
                                    <ProgressText
                                        themeParams={themeParams}
                                        sx={{
                                            fontSize: '12px',
                                            fontWeight: 600,
                                            marginLeft: 'auto',
                                        }}
                                    >
                                        {course.percent}
                                    </ProgressText>
                                </StyledLinearProgressContainer>
                            )}
                        </Box>
                    </Box>
                {index !== student.courses.length - 1 && <Divider />}
                    </>
                ))}
            </Box>


        </Box>
    );
};

export default memo(StudentDetail);