const translations = {
    en: {
        completed: "Done!",
        continue: "Keep Going",
        profile: "My Profile",
        assignments: "Tasks",
        payments: "Payments",
        lessons: "Lessons",
        dashboard: "Home",
        progress: "Progress",
        edit_profile: "Update Profile",
        attempts: "Tries",
        comments: "Feedback",
        created: "Created",
        attempt: "Try",
        start: "Start",
        no_notifications: "No notifications to show.",
        no_assignments: "No tasks to show.",
        status: "Status",
        notifications: "Notifications",
        date: "Date",
        no_payments: "No completed payments ⭐",
        current_step_locked: "Complete this step to unlock the next one.",
        step_locked: "Complete the previous steps so that the content is available.",
        reset_progress: "Reset Progress",
        reset_confirm_message: "Are you sure you want to reset your progress? This action cannot be undone.",
        courses: "Recently Created Courses",
        add_course: "Add Course",
        create_course: "Create Course",
        no_courses: "You don't create any courses yet.",
        create_course_header: "Create a Course to Share Your Knowledge",
        delete_course_confirmation: "Delete Course",
        delete_course_warning: "Are you sure you want to delete this course? This action cannot be undone.",
        delete_step_warning: "Are you sure you want to delete this step? This action cannot be undone.",
        delete_component_warning: "Are you sure you want to delete this component? This action cannot be undone.",
        cancel: "Cancel",
        delete: "Delete",
        save: "Save",
        publish_course: "Publish Course",
        available_bots: "Available Telegram Bots",
        description: "Description",
        title: "Title",
        empty: "None",
        choose_preset: "Choose a Preset",
        choose_image: "Choose Image",
        upload_cover_image: "Upload Cover Image",
        upload: "Upload",
        generate: "Generate",
        edit: "Edit",
        draft: "Draft",
        invoice: "Invoice",
        public: "Public",
        image: "Image",
        text: "Text",
        publish_step: "Publish Step",
        amount: "Amount (Stars)",
        edit_step: "Edit step",
        edit_course: "Edit course",
        courses_br: "Courses",
        Text: "Text",
        Image: "Image",
        Video: "Video",
        Document: "Document",
        Code: "Code",
        Audio: "Audio",
        Checkbox: "Checkbox",
        Painter: "Painter",
        Matcher: "Matcher",
        Transcriber: "Transcriber",
        Substituter: "Substituter",
        CodeVisual: "Code Visual",
        Markdown: "Markdown",
        Editor: "Editor",
        Basic: "Basic",
        Simulator: "Simulator",
        System: "System",
        Assignment: "Assignment",
        Other: "Other",
        Programming: "Programming",
        Codeletix: "Codeletix",
        add_content: "Enter some text and press Enter ...",
        add_content_empty: "Add content to your lesson here ...",
        success_popup_title: "Success!",
        error_popup_title: "Error!",
        warning_popup_title: "Warning!",
        info_popup_title: "Info!",
        close: "Close",
        add_image: "Image",
        add_text: "Text",
        add_invoice: "Invoice",
        content: "Content",
        approved: "Approved",
        rejected: "Rejected",
        pending: "Pending",
        pending_assignments: "Pending Assignments",
        all_assignments: "All Assignments",
        assignment_preview: "Assignment preview",
        assignment_comment: "Comment to the answer",
        actions: "Actions",
        review_assignment_actions: "Review Assignment Actions",
        approve: "Approve",
        decline: "Decline",
        comment_required: "Comment is required",
        assignment: "Assignment",
        reply: "Reply",
        send: "Send",
        courses_menu: "Courses",
        assignments_menu: "Tasks",
        notifications_menu: "Notifications",
        payments_menu: "Payments",
        profile_menu: "Profile",
        bots_menu: "Bots",
        let_feedback: "Leave a comment (optional)",
        subscription_now: "Subscribe",
        subscription_is_active: "Subscription is active",
        in_month: "per month",
        price: "Price",
        no_plan_found: "Plan not found",
        invoice_step: "Invoice Step",
        assignment_plugin: "Assignment Plugin",
        export_course: "Export Course",
        student_statistics: "Student Statistics",
        course_statistics: "Course Statistics",
        custom_plugin: "Custom Plugin",
        ai_generator: "AI Generator",
        yes: "Yes",
        no: "No",
        standard: "Subscription Plan: Standard",
        unlimited: "Subscription Plan: Unlimited",
        premium: "Subscription Plan: Premium",
        create_bot: "Create Bot",
        creating_courses: "Creating Courses",
        subscription_end_date: "Subscription End Date",
        payment_success: "Payment Success",
        constructor: "Constructor",
        statistics: "Statistics",
        students: "Students",
        bots: "Bots",
        no_bots: "No bots to show.",
        set_public: "Set Public",
        course_published: "Course Published",
        publish_course_header: "Ready to publish your course and attach to bot?",
        not_publish_course_header: "Are you sure you want to unpublish this course?",
        bot_select_header: "Select a bot to publish",
        no_bots_message: "You don't have any telegram bots yet 🙁",
        follow_steps_to_create_bot: "Follow the steps below to create a new bot:",
        step_1_find_botfather: "Step 1: Open Telegram and Find @BotFather",
        open_telegram_search_botfather: "Open Telegram and search for @BotFather.",
        select_verified_botfather: "In the search bar at the top, type @BotFather and select the verified account with a blue checkmark.",
        step_2_create_new_bot: "Step 2: Create a New Bot",
        send_newbot_command: "Send the message /newbot to @BotFather.",
        choose_bot_name: "@BotFather will ask you to choose a name for your bot. For example: 'MyLearningBot'.",
        create_bot_username: "Then, you'll need to create a username for your bot. The username must end with 'bot' (e.g., mylearning_bot).",
        step_3_register_bot: "Step 3: Register Your Bot in coob.app",
        forward_api_code: "Forward the message with API code from @BotFather to @coob_app_bot in Telegram.",
        bot_ready_message: "Congratulations! Your bot is now ready to be used in coob project. 🎉",
        select_bot_message: "Select or Create a bot to publish your course.",
        no_students: "You don't have any students yet.",
        no_more_students: "...",
        course_progress: "Course Progress",
        course_title: "Course Title",
        coming_soon: "This section will be available soon",
        plugin_manager: "Install More Components",
        marketplace: "Marketplace",
        installed: "Installed",
        my_components: "My Components",
        components_manager: "Components Manager",
        search_components: "Search components...",
        back: "Back",
        develop_plugin_title: "You have the opportunity to develop a plugin for your course.",
        develop_plugin_description: "Follow the quickstart guide to create a custom plugin for your course.",
        develop_plugin_link_text: "View Documentation",
        by_courses: "Courses",
        filter_by_course: "Filter by Course",
        all_courses: "All",
        clear_filter: "Clear Filter",
        enrolled_courses: "Enrolled Courses",
        username_f: "Username",
        id_f: "ID",
        students_br: "Students",
        no_transactions: "No transactions to show.",
        transactions: "Transactions",
        all: "All",
        incoming: "Incoming",
        refund: "Refund",
    },
    ru: {
        refund: "Возврат",
        all: "Все",
        incoming: "Входящие",
        transactions: "Транзакции",
        no_transactions: "У Вас пока нет транзакций.",
        students_br: "Студенты",
        id_f: "ID",
        username_f: "Имя пользователя",
        enrolled_courses: "Курсы",
        clear_filter: "Сбросить Фильтр",
        all_courses: "Все",
        filter_by_course: "Фильтр по Курсу",
        by_courses: "Курсы",
        develop_plugin_title: "У вас есть возможность разработать плагин для вашего курса.",
        develop_plugin_description: "Следуйте руководству Quickstart, чтобы создать собственный плагин для вашего курса.",
        develop_plugin_link_text: "Посмотреть документацию",
        back: "Назад",
        search_components: "Поиск компонентов...",
        components_manager: "Менеджер Компонентов",
        my_components: "Мои Компоненты",
        installed: "Установлено",
        marketplace: "Маркетплейс",
        plugin_manager: "Установить еще компоненты",
        coming_soon: "Раздел в скором времени будет доступен",
        course_title: "Название Курса",
        course_progress: "Прогресс Обучения",
        no_more_students: "...",
        no_students: "У вас пока нет студентов.",
        select_bot_message: "Выберите или добавьте бота для публикации вашего курса.",
        bot_ready_message: "Поздравляем! Ваш бот теперь готов к использованию в проекте coob. 🎉",
        forward_api_code: "Перешлите сообщение с API-кодом от @BotFather в @coob_app_bot в Telegram.",
        step_3_register_bot: "Шаг 3: Зарегистрируйте своего бота в coob.app",
        create_bot_username: "Затем вам нужно будет создать имя пользователя для вашего бота. Имя пользователя должно заканчиваться на 'bot' (например, mylearning_bot).",
        choose_bot_name: "@BotFather попросит вас выбрать имя для вашего бота. Например: 'MyLearningBot'.",
        send_newbot_command: "Отправьте сообщение /newbot @BotFather.",
        step_2_create_new_bot: "Шаг 2: Создайте нового бота",
        select_verified_botfather: "В строке поиска вверху введите @BotFather и выберите верифицированный аккаунт с голубой галочкой.",
        open_telegram_search_botfather: "Откройте Telegram и найдите @BotFather.",
        step_1_find_botfather: "Шаг 1: Откройте Telegram и найдите @BotFather",
        follow_steps_to_create_bot: "Следуйте шагам ниже, чтобы создать нового бота:",
        no_bots_message: "У вас пока нет телеграм ботов 🙁",

        bot_select_header: "Выберите бота для публикации",
        not_publish_course_header: "Вы уверены, что хотите снять публикацию с этого курса?",
        publish_course_header: "Готовы опубликовать свой курс и привязать к боту?",
        course_published: "Курс Опубликован",
        set_public: "Опубликовать",
        no_bots: "Нет ботов для показа.",
        bots: "Боты",
        students: "Студенты",
        statistics: "Статистика",
        constructor: "Конструктор",
        payment_success: "Оплата прошла успешно",
        subscription_end_date: "Дата окончания подписки",
        creating_courses: "Создание Курсов",
        create_bot: "Добавление Ботов",
        premium: "Подписка: Премиум",
        unlimited: "Подписка: Безлимит",
        standard: "Подписка: Стандарт",
        no: "Нет",
        yes: "Да",
        ai_generator: "Генератор ИИ",
        custom_plugin: "Пользовательский Плагин",
        course_statistics: "Статистика Курса",
        student_statistics: "Статистика Студентов",
        export_course: "Экспорт Курса",
        assignment_plugin: "Компоненты Заданий",
        invoice_step: "Шаг оплаты",
        price: "Стоимость",
        in_month: "в месяц",
        subscription_is_active: "Подписка активна",
        subscription_now: "Подписаться",
        let_feedback: "Оставьте комментарий (опционально)",
        bots_menu: "Боты",
        profile_menu: "Профиль",
        payments_menu: "Платежи",
        notifications_menu: "Уведомления",
        assignments_menu: "Задания",
        courses_menu: "Курсы",
        send: "Отправить",
        reply: "Ответить",
        assignment: "Задание",
        comment_required: "Комментарий обязателен",
        decline: "Отклонить",
        approve: "Принять",
        review_assignment_actions: "Ревью задания",
        assignment_comment: "Комментарий к ответу",
        assignment_preview: "Просмотр задания",
        all_assignments: "Все задания",
        pending_assignments: "Задания ожидающие проверки",
        pending: "В ожидании",
        rejected: "Отклонено",
        content: "Контент",
        add_invoice: "Оплата",
        add_text: "Текст",
        add_image: "Изображение",
        close: "Закрыть",
        info_popup_title: "Информация!",
        warning_popup_title: "Предупреждение!",
        error_popup_title: "Ошибка!",
        success_popup_title: "Отлично!",
        add_content_empty: "Добавляйте контент к вашему уроку здесь ...",
        add_content: "Введите текст и нажмите Enter ...",
        Codeletix: "Кодлетикс",
        Other: "Другое",
        Assignment: "Задание",
        System: "Системные",
        Simulator: "Упражнения",
        Basic: "Основные",
        Editor: "Редактор",
        Markdown: "Маркдаун",
        CodeVisual: "Визуализация Кода",
        Substituter: "Замена",
        Transcriber: "Транскрипция",
        Matcher: "Сопоставление",
        Painter: "Рисование",
        Checkbox: "Чекбокс",
        Audio: "Аудио",
        Code: "Код",
        Document: "Документ",
        Video: "Видео",
        Image: "Изображение",
        Text: "Текст",
        Programming: "Программирование",
        courses_br: "Курсы",
        edit_course: "Редактировать курс",
        edit_step: "Редактировать шаг",
        amount: "Сумма (Stars)",
        publish_step: "Опубликовать Шаг",
        text: "Текст",
        image: "Изображение",
        public: "Опубликовано",
        invoice: "Счет",
        draft: "Черновик",
        edit: "Редактировать",
        generate: "Сгенерировать",
        upload: "Загрузить",
        upload_cover_image: "Загрузить обложку",
        choose_image: "Выберите изображение",
        choose_preset: "Выберите шаблон",
        empty: "Пусто",
        title: "Название",
        description: "Описание",
        available_bots: "Доступные Телеграм Боты",
        publish_course: "Опубликовать Курс",
        save: "Сохранить",
        create_course_header: "Создайте курс, чтобы поделиться своими знаниями",
        create_course: "Создать Курс",
        add_course: "Добавить Курс",
        no_courses: "Вы еще не создали ни одного курса.",
        courses: "Последние созданные курсы",
        completed: "Готово!",
        reset_progress: "Сбросить Прогресс",
        reset_confirm_message: "Вы уверены, что хотите сбросить свой прогресс? Это действие нельзя отменить.",
        delete_step_warning: "Вы уверены, что хотите удалить этот шаг? Это действие нельзя отменить.",
        delete_component_warning: "Вы уверены, что хотите удалить этот компонент? Это действие нельзя отменить.",
        continue: "Продолжить",
        start: "Начать",
        profile: "Мой Профиль",
        notifications: "Уведомления",
        no_notifications: "Нет уведомлений для показа.",
        assignments: "Задания",
        lessons: "Уроки",
        payments: "Платежи",
        dashboard: "Главная",
        progress: "Прогресс",
        edit_profile: "Обновить Профиль",
        attempts: "Попытки",
        comments: "Отзывы",
        created: "Создано",
        attempt: "Попробовать",
        no_assignments: "Нет заданий для показа.",
        status: "Статус",
        date: "Дата",
        no_payments: "Нет совершенных платежей ⭐",
        current_step_locked: "Завершите этот шаг, чтобы разблокировать следующий.",
        step_locked: "Завершите предыдущее шаги, чтобы контент был доступен.",
        delete_course_confirmation: "Удалить Курс",
        delete_course_warning: "Вы уверены, что хотите удалить этот курс? Это действие нельзя отменить.",
        cancel: "Отмена",
        delete: "Удалить",
        approved: "Принято",
        no_plan_found: "План не найден",

    }
};

export function translate(languageCode, key) {
    if (!translations[languageCode]?.[key]) {
        return key;
    }

    return translations[languageCode]?.[key] || translations.en[key];
}