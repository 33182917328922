import React, {useState, useEffect} from 'react';
import axios from 'axios';
import {
    Container,
    Slide,
    Typography,
    Box,
    CircularProgress,
    IconButton,
    Backdrop,
    Radio,
    RadioGroup,
    FormControlLabel
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import {styled} from '@mui/system';
import {useDispatch, useSelector} from 'react-redux';
import {translate} from '../translations';
import useWebApp from "../twa/useWebApp";
import SubscriptionDialog from "../SubscriptionDialog";
import {setSelectedCourse} from "../store";

const StyledRadioGroup = styled(RadioGroup)(({themeParams}) => ({
    display: 'flex',
    flexDirection: 'column',
    gap: '8px',
    borderRadius: '16px',
    padding: '8px',
    backgroundColor: themeParams.secondary_bg_color,
    '& .MuiFormControlLabel-root': {
        borderRadius: '16px',
        padding: '8px 12px',
    },
    '& .MuiRadio-root': {
        color: themeParams.text_color,
    },
}));

const ScrollableContainer = styled(Box)(({themeParams}) => ({
    maxHeight: '400px', // Фиксированная высота
    overflowY: 'auto', // Прокрутка по вертикали
    marginTop: '16px',
    borderRadius: '16px',
    padding: '8px',
}));

const PublishDialog = ({themeParams, open, onClose, language, course_id, active_bot_id}) => {
    const [loading, setLoading] = useState(true);
    const [bots, setBots] = useState([]);
    const [selectedBot, setSelectedBot] = useState(active_bot_id || null);
    const token = useSelector(state => state.accessToken);
    let WebApp = useWebApp();
    const [isSubscriptionDialogOpen, setSubscriptionDialogOpen] = useState(false);
    const dispatch = useDispatch();

    useEffect(() => {
        const handleClick = async () => {
            if (!selectedBot) {
                WebApp.showAlert(translate(language, 'select_bot_message'));
                return;
            }

            WebApp.MainButton.showProgress(false);
            try {
                const response = await axios.post(
                    `https://api.coob.app/v1/courses/${course_id}/publish`,
                    {
                        bot_id: selectedBot,
                        is_publish: true
                    },
                    {
                        headers: {
                            Authorization: `Bearer ${token}`,
                        },
                    }
                );

                if (response.status === 200) {
                    dispatch(setSelectedCourse(response.data.data));
                    onClose();
                }

            } catch (error) {
                if (error.response?.data?.type === 'plan_limit_exceeded') {
                    setSubscriptionDialogOpen(true);
                }

                WebApp.MainButton.hideProgress();
                console.error('Failed to publish course:', error);
                WebApp.showAlert("error: " + error.response.data.data.message);
            }
        };

        if (open && WebApp) {
            WebApp.MainButton.show();

            if (selectedBot) {
                WebApp.MainButton.setParams({
                    text: translate(language, 'set_public'),
                    is_active: true,
                    text_color: themeParams.button_text_color,
                    color: themeParams.button_color,
                });
            } else if (!selectedBot) {
                WebApp.MainButton.setParams({
                    text: translate(language, 'set_public'),
                    color: themeParams.bg_color,
                    text_color: themeParams.subtitle_text_color,
                    is_active: false,
                });
            }

            WebApp.MainButton.onClick(handleClick);
        }

        return () => {
            if (WebApp) {
                WebApp.MainButton.offClick(handleClick);
                WebApp.MainButton.hideProgress();
                WebApp.MainButton.hide();
                if (!open) setSelectedBot(active_bot_id || null);
            }
        };
    }, [open, WebApp, selectedBot]);

    useEffect(() => {
        const fetchBots = async () => {
            try {
                const response = await axios.get(
                    `https://api.coob.app/v1/bots/available?limit=100${active_bot_id ? `&active_bot_id=${active_bot_id}` : ''}`,
                    {
                        headers: {
                            Authorization: `Bearer ${token}`,
                        },
                    }
                );

                if (response.data && response.data.code === 'success') {
                    //setBots([response.data.data[0]]);
                    setBots(response.data.data);
                  // setBots([]);
                }
            } catch (error) {
                console.error('Failed to fetch bots:', error);
            } finally {
                setLoading(false);
            }
        };

        if (open) {
            fetchBots();
        }
    }, [open, token, active_bot_id]);

    return (
        <>
            <Backdrop open={open} onClick={onClose} sx={{zIndex: 1200, backgroundColor: 'rgba(0, 0, 0, 0.5)'}}/>
            <Slide direction="up" in={open} mountOnEnter unmountOnExit>
                <Box
                    sx={{
                        position: 'fixed',
                        bottom: 0,
                        left: 0,
                        right: 0,
                        zIndex: 1300,
                        backgroundColor: themeParams.section_bg_color,
                        borderTopLeftRadius: '16px',
                        borderTopRightRadius: '16px',
                        padding: '16px',
                        color: themeParams.text_color,
                    }}
                    onClick={onClose}
                >
                    <Container onClick={(e) => e.stopPropagation()}>
                        <Box
                            sx={{
                                display: 'flex',
                                justifyContent: 'space-between',
                                alignItems: 'center',
                                paddingBottom: '8px',
                                marginBottom: '16px',
                            }}
                        >
                            <Typography
                                sx={{
                                    fontSize: '14px',
                                    fontWeight: 'bold',
                                    textTransform: 'uppercase',
                                }}
                            >
                                {translate(language, 'bot_select_header')}
                            </Typography>
                            <IconButton
                                sx={{color: themeParams.text_color}}
                                onClick={onClose}
                            >
                                <CloseIcon/>
                            </IconButton>
                        </Box>
                        {loading ? (
                            <Box
                                sx={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                                <CircularProgress sx={{color: themeParams.button_color}}/>
                            </Box>
                        ) : (

                            <ScrollableContainer themeParams={themeParams}>
                                {bots.length === 0 ? (

                                    <Box
                                        sx={{
                                            display: 'flex',
                                            justifyContent: 'center',
                                            alignItems: 'center',
                                            flexDirection: 'column',
                                            padding: '16px',
                                            gap: '16px',
                                            borderRadius: '16px',
                                            backgroundColor: themeParams.secondary_bg_color
                                        }}
                                    >
                                        {/* Основной заголовок */}
                                        <Typography
                                            sx={{
                                                fontSize: '18px',
                                                fontWeight: 'bold',
                                                color: themeParams.text_color,
                                                textAlign: 'center',
                                            }}
                                        >
                                            {translate(language, 'no_bots_message')}
                                        </Typography>
                                        <Typography
                                            sx={{
                                                color: themeParams.subtitle_text_color,
                                                }}
                                        >{translate(language, 'follow_steps_to_create_bot')}</Typography>

                                        {/* Основной текст-инструкция */}
                                        <Box
                                            sx={{
                                                display: 'flex',
                                                flexDirection: 'column',
                                                alignItems: 'flex-start',
                                                textAlign: 'left',
                                                gap: '12px',
                                                width: '100%',
                                                padding: '12px',
                                                borderRadius: '12px'
                                            }}
                                        >
                                            {/* Шаг 1 */}
                                            <Typography variant="h6" sx={{ fontSize: '16px', fontWeight: 'bold', color: themeParams.accent_text_color }}>
                                                {translate(language, 'step_1_find_botfather')}
                                            </Typography>
                                            <ul style={{ margin: 0, paddingLeft: '20px', fontSize: '14px', }}>
                                                <li style={{ color: themeParams.text_color }}>
                                                    1) {translate(language, 'select_verified_botfather')}
                                                </li>
                                            </ul>

                                            {/* Шаг 2 */}
                                            <Typography variant="h6" sx={{ fontSize: '16px', fontWeight: 'bold', color: themeParams.accent_text_color }}>
                                                {translate(language, 'step_2_create_new_bot')}
                                            </Typography>
                                            <ul style={{ margin: 0, paddingLeft: '20px', fontSize: '14px' }}>
                                                <li style={{ marginBottom: '4px', color: themeParams.text_color }}>
                                                    1) {translate(language, 'send_newbot_command')}
                                                </li>
                                                <li style={{ marginBottom: '4px', color: themeParams.text_color }}>
                                                    2) {translate(language, 'choose_bot_name')}
                                                </li>
                                                <li style={{ color: themeParams.text_color }}>
                                                    3) {translate(language, 'create_bot_username')}
                                                </li>
                                            </ul>

                                            {/* Шаг 3 */}
                                            <Typography variant="h6" sx={{ fontSize: '16px', fontWeight: 'bold', color: themeParams.accent_text_color }}>
                                                {translate(language, 'step_3_register_bot')}
                                            </Typography>
                                            <ul style={{ margin: 0, paddingLeft: '20px', fontSize: '14px', }}>
                                                <li style={{ color: themeParams.text_color }}>
                                                    1) {translate(language, 'forward_api_code')}
                                                </li>
                                            </ul>
                                        </Box>

                                        {/* Заключительный текст */}
                                        <Typography
                                            sx={{
                                                fontSize: '14px',
                                                color: themeParams.text_color,
                                                textAlign: 'center',
                                                marginTop: '8px',
                                            }}
                                        >
                                            {translate(language, 'bot_ready_message')}
                                        </Typography>
                                    </Box>

                                ) : (
                                    <StyledRadioGroup
                                        value={selectedBot}
                                        onChange={(e) => setSelectedBot(e.target.value)}
                                        themeParams={themeParams}
                                    >
                                        {bots.map((bot) => (
                                            <FormControlLabel
                                                key={bot.id}
                                                value={bot.id}
                                                control={<Radio/>}
                                                label={
                                                    <Box sx={{display: 'flex', flexDirection: 'column', gap: '4px'}}>
                                                        <Typography sx={{fontSize: '16px', color: themeParams.accent_text_color}}>
                                                            {bot.name}
                                                        </Typography>
                                                        <Typography sx={{
                                                            fontSize: '12px',
                                                            color: themeParams.subtitle_text_color
                                                        }}>
                                                            {bot.token.substring(0, 35) + '...'}
                                                        </Typography>
                                                    </Box>
                                                }
                                            />
                                        ))}
                                    </StyledRadioGroup>
                                )}
                            </ScrollableContainer>

                        )}

                    </Container>
                </Box>
            </Slide>
            <SubscriptionDialog
                themeParams={themeParams}
                open={isSubscriptionDialogOpen}
                onClose={() => setSubscriptionDialogOpen(false)}
                language={language}
            />
        </>
    );
};

export default PublishDialog;