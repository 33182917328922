import React, { useState, useEffect, useRef, memo } from 'react';
import axios from 'axios';
import { Card, Typography, CircularProgress, IconButton, Divider } from '@mui/material';
import { styled } from '@mui/system';
import { formatDistance } from 'date-fns';
import { useSelector} from 'react-redux';
import SkeletonCourse from './SkeletonCourse';
import CoverGenerator from './CoverGenerator';
import { translate } from '../translations';
import './Courses.css';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import { useNavigate } from 'react-router-dom';
import { Button, Section, Placeholder } from '@telegram-apps/telegram-ui';
import Header from "../Header";
import useWebApp from '../twa/useWebApp';
import SubscriptionDialog from '../SubscriptionDialog';
import { useLocation } from 'react-router-dom';

const Courses = ({ user, themeParams, language }) => {
    const navigate = useNavigate();
    const location = useLocation();
    const [courses, setCourses] = useState([]);
    const [loading, setLoading] = useState(true);
    const [hasMore, setHasMore] = useState(true);
    const [isFetching, setIsFetching] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const accessToken = useSelector(state => state.accessToken);
    const colorScheme = useWebApp()?.colorScheme || 'light';
    const WebApp = useWebApp();
    const [isSubscriptionDialogOpen, setSubscriptionDialogOpen] = useState(false);
    const didFetch = useRef(false);

    const isHomePage = location.pathname === '/';

    const handleCardClick = (courseId) => {
        navigate(`/courses/${courseId}`);
    };

    const StyledCard = styled(Card)({
        backgroundColor: themeParams.section_bg_color,
        borderRadius: '20px',
        margin: '20px 30px',
        boxShadow: '0 2px 10px rgba(0, 0, 0, 0.1)',
        overflow: 'hidden',
        position: 'relative',
    });

    const ImageWrapper = styled('div')({
        width: '100%',
        height: '200px',
        overflow: 'hidden',
    });

    const AddContentText = styled('div')(({ theme }) => ({
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center', // Центрирование по горизонтали
        justifyContent: 'center', // Центрирование по вертикали
        textAlign: 'center',
        color: theme.palette.text.disabled,
        padding: theme.spacing(5),
        fontSize: '20px',
        paddingTop: theme.spacing(5),
        marginBottom: theme.spacing(5),
    }));

    const StyledImage = styled('img')({
        width: '100%',
        height: '100%',
        objectFit: 'cover',
    });

    const ContentWrapper = styled('div')({
        padding: '16px',
    });

    const TitleTypography = styled(Typography)({
        marginTop: '8px',
        fontSize: '1.1rem',
        fontWeight: '500',
        color: themeParams.text_color,
        textAlign: 'left',
    });

    const DescriptionTypography = styled(Typography)({
        marginTop: '8px',
        color: themeParams.text_secondary_color,
        fontSize: '0.875rem',
        textAlign: 'left',
        lineClamp: 2,
        display: '-webkit-box',
        WebkitBoxOrient: 'vertical',
        overflow: 'hidden',
    });

    useEffect(() => {
        const fetchCourses = async () => {
            setLoading(true);
            try {
                const response = await axios.get(`/v1/courses?size=100&page=1`, {
                    headers: {
                        'Authorization': `Bearer ${accessToken}`
                    }
                });

                const newCourses = response.data.data || [];
                setCourses(newCourses);
            } catch (error) {
                console.error("Ошибка при загрузке курсов:", error);
            } finally {
                setLoading(false);
            }
        };

        if (!didFetch.current && accessToken && isHomePage) {
            fetchCourses();
            didFetch.current = true; // Флаг "выполнено" для предотвращения повторного вызова
        }
    }, [accessToken, isHomePage]);

    const createCourseClick = () => {
        createNewCourse();
    };

    const createNewCourse = async () => {
        if (isLoading) return;

        setIsLoading(true);

        try {
            const response = await axios.post('/v1/courses', {
                title: "Untitled",
                description: "default course description"
            }, {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${accessToken}`,
                }
            });

            if (response.data.code === "success") {
                navigate(`/courses/${response.data.data}`);
            } else {
                console.error("Ошибка при создании курса:", response.data);
            }
        } catch (error) {
            if (error.response?.data?.type === 'plan_limit_exceeded') {
                setSubscriptionDialogOpen(true);
            }
            console.error("Ошибка при создании курса:", error);
        } finally {
            setIsLoading(false);
        }
    };

    const handleDeleteClick = (course) => {
        WebApp.showConfirm(translate(language, 'delete_course_warning'), async (agree) => {
            if (agree) {
                try {
                    await axios.delete(`/v1/courses/${course.id}`, {
                        headers: {
                            'Authorization': `Bearer ${accessToken}`
                        }
                    });
                    setCourses(courses.filter(c => c.id !== course.id));
                } catch (error) {
                    if (error.response.data.type === 'plan_limit_exceeded') {
                        setSubscriptionDialogOpen(true);
                    }

                    console.error("Ошибка при удалении курса:", error);
                }
            }
        });
    };

    return (
        <div>
            <Header language={language} user={user} dark={colorScheme === 'dark'} themeParams={themeParams} />
            <Section
                className="section-add"
                header={translate(language, 'create_course_header')}
                footer={translate(language, 'courses')}
            >
                <div className="section-button-container">
                    <Button
                        onClick={createCourseClick}
                        className="section-button"
                        mode="bezeled"
                        before={<AddIcon />}
                        loading={isLoading}
                    >
                        {translate(language, 'create_course')}
                    </Button>
                </div>
            </Section>

            {loading ? (
                <>
                    <SkeletonCourse themeParams={themeParams} />
                    <SkeletonCourse themeParams={themeParams} />
                </>
            ) : (
                courses && courses.length > 0 ? (
                    courses.map(course => (
                        <StyledCard key={course.id} onClick={() => handleCardClick(course.id)}>
                            <ImageWrapper>
                                {course.image_url ? (
                                    <StyledImage src={course.image_url} alt={course.title || 'Course image'} />
                                ) : (
                                    course.settings?.course_cover_preset && (
                                        <CoverGenerator
                                            width={300}
                                            preset={course.settings.course_cover_preset}
                                            text={course.title || 'Untitled'}
                                        />
                                    )
                                )}
                            </ImageWrapper>
                            <ContentWrapper>
                                <TitleTypography>{course.title || 'Untitled Course'}</TitleTypography>
                                <Divider sx={{ my: 1, backgroundColor: themeParams.section_separator_color }} />
                                <DescriptionTypography>{course.description || 'No description available.'}</DescriptionTypography>
                                <div className="flex justify-between items-center mt-4">
                                    <div style={{ flexGrow: 1 }}></div>
                                    <Typography
                                        sx={{
                                            color: themeParams.section_header_text_color,
                                            textAlign: 'right',
                                        }}
                                        className="text-sm"
                                    >
                                        {formatDistance(new Date(course.created_at), new Date(), { addSuffix: true })}
                                    </Typography>
                                </div>
                                <IconButton
                                    sx={{
                                        position: 'absolute',
                                        top: 10,
                                        right: 10,
                                        backgroundColor: `${themeParams.button_color}AA`,
                                        backgroundImage: 'none',
                                        backdropFilter: 'blur(5px)',
                                        '&:hover': {
                                            backgroundColor: `${themeParams.button_color}CC`,
                                        },
                                    }}
                                    onClick={(event) => {
                                        event.stopPropagation();
                                        handleDeleteClick(course);
                                    }}
                                    aria-label="delete"
                                >
                                    <DeleteIcon sx={{ color: themeParams.button_text_color }} />
                                </IconButton>
                            </ContentWrapper>
                        </StyledCard>
                    ))
                ) : (
                    <Placeholder>
                        <AddContentText>
                            <img src="https://coob.app/assets/images/rob.png" alt="empty" style={{
                                borderRadius: '20px',
                                padding: 10,
                                height: 250,
                                opacity: 0.8,
                            }}/>
                            {translate(language, 'no_courses')}
                        </AddContentText>
                    </Placeholder>
                )
            )}
            {isFetching && (
                <div style={{display: 'flex', justifyContent: 'center', margin: '20px 0'}}>
                    <CircularProgress/>
                </div>
            )}
            {!hasMore && !loading && courses.length === 0 && (
                <Placeholder description={translate(language, 'no_courses')}>
                </Placeholder>
            )}
            <SubscriptionDialog
                themeParams={themeParams}
                open={isSubscriptionDialogOpen}
                onClose={() => setSubscriptionDialogOpen(false)}
                language={language}
            />
        </div>
    );
};

export default memo(Courses);